import React from "react"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import headerStyles from "./header.module.css"

export default function Header(props) {
  return (<div className={headerStyles.header}>
    <div>
      <Link to="/"><Button>Taogi</Button></Link> <Link to="/about/"><Button>About</Button></Link> <Link to="/contact"><Button>Contact</Button></Link>
    </div>
    <h1>{props.headerText}</h1>
  </div>)
}